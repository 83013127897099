import React, { useMemo, useState } from "react"

import Layout from "../components/layouts/HomePageLayout"

import "./about.css"
import SocialLinks from "../components/SocialLinks"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import { BackgroundImage } from "../components/BackgroundImage"
import classNames from "classnames"
// import { useAffixInView } from "../hooks/useAffixInView"
// import { TeamItemCard } from "../components/TeamItemCard"
// import { useTeamData } from "../hooks/useTeamData"
// import { useScrollAdjust } from "../hooks/useScrollAdjust"
import { HeaderVariants } from "../components/header"
import ReactFullpage from "@fullpage/react-fullpage"

import logo1 from "../images/logos/1.jpg"
import logo2 from "../images/logos/2.jpg"
import logo3 from "../images/logos/3.jpg"
import logo4 from "../images/logos/4.jpg"
import logo5 from "../images/logos/5.jpg"
import logo6 from "../images/logos/6.jpg"
import logo7 from "../images/logos/7.jpg"
import logo8 from "../images/logos/8.jpg"

import mouseIcon from "../images/mouse.svg"

// const getDataForCategory = (data, category) => data.filter(datum => datum.frontmatter.category === category)

const usePageSectionIcons = () =>
  useStaticQuery(graphql`
    query {
      vision: file(relativePath: { eq: "vision-icon.png" }) {
        publicURL
      }
      mission: file(relativePath: { eq: "mission-icon.png" }) {
        publicURL
      }
      enterpriseDivision: file(
        relativePath: { eq: "enterprise-division-icon.png" }
      ) {
        publicURL
      }
      businessServices: file(
        relativePath: { eq: "business-services-icon.png" }
      ) {
        publicURL
      }
      consultingServices: file(
        relativePath: { eq: "consulting-services-icon.png" }
      ) {
        publicURL
      }
    }
  `)

const AboutPage = () => {
  const {
    vision: { publicURL: visionIconURL },
    mission: { publicURL: missionIconURL },
    enterpriseDivision: { publicURL: enterpriseDivisionIconURL },
    businessServices: { publicURL: businessServicesIconURL },
    consultingServices: { publicURL: consultingServicesIconURL },
  } = usePageSectionIcons()
  const [activeSection, setActiveSection] = useState("who-we-are")
  // const affixedCallback = el => {
  //   setActiveSection(el.getAttribute("id"))
  // }
  // const backgroundClasses = useMemo(
  //   () =>
  //     classNames({
  //       "background-white": activeSection === "what-we-do",
  //       // "background-black": activeSection === "our-team",
  //     }),
  //   [activeSection]
  // )
  // const teamData = useTeamData()
  // const teamCategories = ["Leadership", "Staff"]
  const currentHeaderVariant = useMemo(() => {
    switch (activeSection) {
      case "who-we-are":
      case "our-team":
        return HeaderVariants.light
      case "what-we-do":
        return HeaderVariants.dark
    }
  }, [activeSection])
  const currentBackgroundImage = useMemo(() => {
    if (activeSection === "who-we-are") {
      return <BackgroundImage imageName="laptop.jpg" />
    } else {
      return null
    }
  }, [activeSection])
  // useAffixInView(".js-affix", affixedCallback)
  // useScrollAdjust("slower")
  return (
    <Layout
      pageTitle="About"
      headerVariant={currentHeaderVariant}
      background={activeSection === "our-team"}
    >
      <SEO title="About" />
      <div className="about-page">
        <div role="presentation" tabIndex={-1} className="background">
          {currentBackgroundImage}
        </div>
        <ReactFullpage
          normalScrollElements=".right-pane"
          scrollOverflow={true}
          onLeave={(_, destination) => {
            const { item } = destination
            if (item.dataset.section) {
              setActiveSection(item.dataset.section)
            }
          }}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section" data-section="who-we-are">
                  <div className="container">
                    <section
                      className={classNames(["two-paned-view about-section"])}
                    >
                      <div className="left-pane" id="who-we-are">
                        <h1 className="title">who we are</h1>
                        <p className="description">
                          Prunedge is a technology company that develops
                          innovative solutions that improve efficiency of
                          processes, livelihood of people and aid decision
                          making within organizations. We actively work to put a
                          smile on people's faces by showing them the immense
                          benefits of technology and delivering impact-focused
                          and outstanding solutions and inventions.
                        </p>
                        <div className="divider" />
                        <SocialLinks />
                      </div>
                      <div
                        className="right-pane js-affix"
                        data-affix-target="#who-we-are"
                      >
                        <div className="info-section">
                          <img
                            src={visionIconURL}
                            alt="vision"
                            className="info-section-icon"
                          />
                          <h5 className="info-section-title">vision</h5>
                          <p className="info-section-description">
                            To solve the world’s greatest problems through
                            technology.
                          </p>
                        </div>
                        <div className="info-section">
                          <img
                            src={missionIconURL}
                            alt="mission"
                            className="info-section-icon"
                          />
                          <h5 className="info-section-title">mission</h5>
                          <p className="info-section-description">
                            We exist to innovate and create affordable
                            technological solutions that solve day to day human
                            problems by increasing the efficiency of systems and
                            processes within the social, commercial and public
                            sector space.
                          </p>
                        </div>
                      </div>
                    </section>
                    <button
                      className="mouse-button"
                      onClick={() => fullpageApi.moveSectionDown()}
                    >
                      <img src={mouseIcon} alt="" />
                    </button>
                  </div>
                </div>
                <div
                  className="section background-white"
                  data-section="what-we-do"
                >
                  <div className="container">
                    <section
                      className={classNames(["two-paned-view about-section"])}
                    >
                      <div className="left-pane" id="what-we-do">
                        <h1 className="title">What we do</h1>
                        <p className="description">
                          We develop robust software technology solutions and
                          render technology services with our team of competent
                          professionals using the best tools within the industry
                          to deliver world-class quality
                        </p>
                        <h5 className="clients__title">top clients</h5>
                        <div className="clients__grid">
                          <div>
                            <img
                              src={logo1}
                              alt=""
                              style={{
                                maxWidth: "165px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo2}
                              alt=""
                              style={{
                                maxWidth: "100px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo3}
                              alt=""
                              style={{
                                maxWidth: "50px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo4}
                              alt=""
                              style={{
                                maxWidth: "100px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo5}
                              alt=""
                              style={{
                                maxWidth: "100px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo6}
                              alt=""
                              style={{
                                maxWidth: "50px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo7}
                              alt=""
                              style={{
                                maxWidth: "50px",
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={logo8}
                              alt=""
                              style={{
                                maxWidth: "50px",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="right-pane js-affix"
                        data-affix-target="#what-we-do"
                      >
                        <div className="info-section">
                          <img
                            src={enterpriseDivisionIconURL}
                            alt="enterprise division"
                            className="info-section-icon"
                          />
                          <h5 className="info-section-title">
                            enterprise division
                          </h5>
                          <p className="info-section-description">
                            Enterprise Products are solutions created to solve
                            pressing business challenges and meet the
                            technological needs of organizations, corporate
                            bodies and public/private establishments. Our
                            current solutions are a mix of horizontal and
                            vertical SaaS solutions; Retail management software,
                            Learning Management software , public sector and
                            non-profit software, compliance and risk management
                            software, analytics and business intelligence
                            solution.
                          </p>
                        </div>
                        <div className="info-section">
                          <img
                            src={businessServicesIconURL}
                            alt="business services"
                            className="info-section-icon"
                          />
                          <h5 className="info-section-title">
                            business services
                          </h5>
                          <p className="info-section-description">
                            Our consulting services are first-rate services
                            geared towards assisting organizations to optimize
                            the use of technology to advance value creation and
                            profitability. We create technology-driven digital
                            strategies and roadmaps that help to improve
                            operations within organizations. Our consultancy
                            services are highlighted below:
                          </p>
                          <ul className="info-subsection-list">
                            <li className="info-subsection">
                              <h5 className="info-subsection-title">
                                Advisory Services
                              </h5>
                              <p>
                                We carry out technology-focused advisory
                                services based extensive research and a thorough
                                analysis of your business needs, challenges and
                                operations
                              </p>
                            </li>
                            <li className="info-subsection">
                              <h5 className="info-subsection-title">
                                Technical Services
                              </h5>
                              <p>
                                Our technical services perform technology audit
                                within your organization by evaluating existing
                                software solutions , identifying problems in
                                workflows and automation and proferring
                                solutions to improve efficiency in the
                                organization
                              </p>
                            </li>
                            <li className="info-subsection">
                              <h5 className="info-subsection-title">
                                Management Services
                              </h5>
                              <p>
                                We render topnotch technical support services
                                for organizations through our support team that
                                keeps track of your system performance, identify
                                pain points and fix them to ensure a seamless
                                work flow within your organization
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="info-section">
                          <img
                            src={consultingServicesIconURL}
                            alt="consulting services"
                            className="info-section-icon"
                          />
                          <h5 className="info-section-title">
                            Consulting Services
                          </h5>
                          <p className="info-section-description">
                            Our business services division offers technology
                            expertise essential for smooth running of business
                            operations. Some of our business services are:
                          </p>
                          <ul className="consulting-services-list">
                            <li>Cyber Security and Network setup</li>
                            <li>Email Migration and Setup</li>
                            <li>
                              IT infrastructure, hosting and IT distribution
                              service
                            </li>
                            <li>Data analytics and visualization</li>
                          </ul>
                        </div>
                      </div>
                    </section>
                    {/*<button*/}
                    {/*  className="mouse-button"*/}
                    {/*  onClick={() => fullpageApi.moveSectionDown()}*/}
                    {/*>*/}
                    {/*  <img src={mouseIcon} alt="" />*/}
                    {/*</button>*/}
                  </div>
                </div>
                {/*<div*/}
                {/*  className="section background-black"*/}
                {/*  data-section="our-team"*/}
                {/*>*/}
                {/*  <div className="container">*/}
                {/*    <section className={classNames(["about-section"])}>*/}
                {/*      <div id="our-team">*/}
                {/*        <h1 className="title">Our team</h1>*/}
                {/*        <ul className="team-list">*/}
                {/*          <li className="team-list__item">*/}
                {/*            <h6 className="team-list__item-title">*/}
                {/*              We are impact driven;*/}
                {/*            </h6>*/}
                {/*            <p className="team-list__item-description">*/}
                {/*              We give of our time to ensure that everyone can*/}
                {/*              experience the joy of the technology and the*/}
                {/*              opportunities it brings.*/}
                {/*            </p>*/}
                {/*          </li>*/}
                {/*          <li className="team-list__item">*/}
                {/*            <h6 className="team-list__item-title">*/}
                {/*              We are technology enthusiasts;*/}
                {/*            </h6>*/}
                {/*            <p className="team-list__item-description">*/}
                {/*              We believe that technology will change the future*/}
                {/*              and we work daily to create that future that we*/}
                {/*              want to see.*/}
                {/*            </p>*/}
                {/*          </li>*/}
                {/*          <li className="team-list__item">*/}
                {/*            <h6 className="team-list__item-title">*/}
                {/*              We are value creators;*/}
                {/*            </h6>*/}
                {/*            <p className="team-list__item-description">*/}
                {/*              We combine our talents and super powers to ensure*/}
                {/*              that our solutions take orgnaizations to the*/}
                {/*              highest trajectory and profitability.*/}
                {/*            </p>*/}
                {/*          </li>*/}
                {/*          <li className="team-list__item">*/}
                {/*            <h6 className="team-list__item-title">*/}
                {/*              We are one happy family;*/}
                {/*            </h6>*/}
                {/*            <p className="team-list__item-description">*/}
                {/*              We put in our best in the work we do to achieve a*/}
                {/*              common goal.*/}
                {/*            </p>*/}
                {/*          </li>*/}
                {/*          <li className="team-list__item">*/}
                {/*            <h6 className="team-list__item-title">*/}
                {/*              We are team players;*/}
                {/*            </h6>*/}
                {/*            <p className="team-list__item-description">*/}
                {/*              We encourage each other to bring out our strengths*/}
                {/*              and competencies.*/}
                {/*            </p>*/}
                {/*          </li>*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*      <div className="js-affix" data-affix-target="#our-team">*/}
                {/*        <div className="team-details">*/}
                {/*          {teamCategories.map(teamCategory => {*/}
                {/*            const categoryData = getDataForCategory(*/}
                {/*              teamData,*/}
                {/*              teamCategory*/}
                {/*            )*/}
                {/*            return (*/}
                {/*              <React.Fragment key={teamCategory}>*/}
                {/*                <div className="team-category-items">*/}
                {/*                  {categoryData*/}
                {/*                    .sort((a, b) =>*/}
                {/*                      a.frontmatter.index > b.frontmatter.index*/}
                {/*                        ? 1*/}
                {/*                        : -1*/}
                {/*                    )*/}
                {/*                    .map(({ frontmatter, html }) => (*/}
                {/*                      <TeamItemCard*/}
                {/*                        key={frontmatter.name}*/}
                {/*                        name={frontmatter.name}*/}
                {/*                        role={frontmatter.role}*/}
                {/*                        image={frontmatter.image}*/}
                {/*                        html={html}*/}
                {/*                      />*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*              </React.Fragment>*/}
                {/*            )*/}
                {/*          })}*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </section>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </ReactFullpage.Wrapper>
            )
          }}
        />
      </div>
      {/*<div*/}
      {/*  className={classNames([*/}
      {/*    "about-page header-fixed-adjust",*/}
      {/*    backgroundClasses,*/}
      {/*  ])}*/}
      {/*>*/}
      {/*  */}
      {/*</div>*/}
    </Layout>
  )
}

export default AboutPage
